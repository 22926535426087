import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import { Avatar, Card, CardContent, Container, Divider, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import moment from 'moment';
import { useSelector } from 'react-redux';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
import { useSettingsContext } from 'src/components/settings';
import { formatDateTime, timStampToDate } from 'src/core/constant';
import AxiosAll from 'src/service/axiosAll';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

// ----------------------------------------------------------------------

export default function CustomerDetails() {
  const { themeStretch } = useSettingsContext();

  const navigate = useNavigate();
  const params = useParams();
  const [customer, setCustomer] = useState([]);

  // get redux state
  const user = useSelector((state) => state.user);
  const { token } = user;
  const [outcomes, setOutcomes] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const getVisitDetail = async () => {
    try {
      const response = await AxiosAll('get', `customers/${params?.id}`, null, token);
      const { data } = response;
      setCustomer(data?.data);
      setOutcomes(data?.outcomes);
    } catch (error) {
      enqueueSnackbar('Failed to fetch customer details. Please try again later.', {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    getVisitDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  const methods = useForm({
    resolver: yupResolver(),
  });

  const {
    reset,
    watch,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();
  function handleLinkClick(link) {
    window.open(link, '_blank');
  }

  return (
    <Page title="Customers : Detail">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Customers Details"
          links={[
            { name: 'Dashboard', href: '/dashboard' },
            { name: 'Customer list', href: '/dashboard/customer' },
            { name: 'Details' },
          ]}
        />
        <Card>
          <Typography variant="h4" sx={{ p: 3 }}>
            {customer?.name}
          </Typography>
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Customer Name
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {customer?.name || '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Customer Type
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {customer?.customerType?.name || '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Alternate Number
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {customer?.alternateNumber || '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Email
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {customer?.email || '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Store Name
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {customer?.storeName || '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Outstanding
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {customer?.outstanding || '₹ 0'}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Family Name
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {customer?.familyName || '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Given Name
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {customer?.givenName || '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Created At
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {customer?.createdAt || '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Updated At
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {customer?.updatedAt || '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Extra Info
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {customer?.extraInfo || '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Sales Man
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {customer?.salesman?.firstName || '-'} {customer?.salesman?.lastName || '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Image
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <Avatar
                    alt="Product Image"
                    id="fileInput"
                    src={customer?.image || '-'}
                    style={{ width: '100px', height: '100px', cursor: 'pointer' }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
