import PropTypes from 'prop-types';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
// _mock
import { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';
import AxiosAll from 'src/service/axiosAll';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useSnackbar } from 'notistack';
import { RHFTextField } from '../hook-form';
import FormProvider from '../hook-form/form-provider';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

AddUserModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  getUser: PropTypes.func,
};

export default function AddUserModal({ open, onClose, getUser }) {
  const NewAddressSchema = Yup.object().shape({
    fName: Yup.string().required('First Name is required'),
    lName: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email Format').required('Email is required'),

    phone: Yup.string()
      .required('Phone number is required')
      .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits long'),
  });

  const user = useSelector((state) => state.user);
  const { token } = user;
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [positions, setPositions] = useState([]);
  const [managers, setManagers] = useState([]);
  const [userPosition, setUserPosition] = useState({ value: '', lable: '' });
  const [managerPosition, setManagerPosition] = useState({ value: '', lable: '' });

  const defaultValues = useMemo(
    () => ({
      fName: '',
      lName: '',
      phone: '',
      email: '',
      position: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewAddressSchema),
    defaultValues,
  });
  const { reset, handleSubmit } = methods;

  const handleOnClose = () => {
    reset(defaultValues);
    setUserPosition({ value: '', lable: '' });
    setManagerPosition({ value: '', lable: '' });
    onClose();
  };

  const onSubmit = async (value) => {
    const data1 = {
      firstName: value?.fName,
      lastName: value?.lName,
      email: value?.email,
      phone: value?.phone,
      positionId: userPosition.value,
      managerId: managerPosition.value,
      teamId: 2, // hardcoding for salesperson
    };
    try {
      const response = await AxiosAll('post', `auth/register`, data1, token);
      const { data } = response;
      if (data?.statusCode === 201 || data?.statusCode === 200) {
        handleOnClose();
        reset(defaultValues);
        setLoading(false);
        getUser();
        enqueueSnackbar(response?.data?.message, { variant: 'success' });
      } else {
        handleOnClose();
        enqueueSnackbar(response?.data?.message, { variant: 'error' });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error?.data?.message, { variant: 'success' });
    }
  };

  const handleChange = (event) => {
    setUserPosition({ value: event.target.value, lable: event.target.name });
    setManagerPosition({ value: '', lable: '' });
  };

  const ManageChange = (event) => {
    setManagerPosition({ value: event.target.value, label: event.target.name });
  };

  useEffect(() => {
    getPositionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getManagerDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPosition.value]);

  const getPositionData = async () => {
    const response = await AxiosAll('get', 'positions', {}, user?.token);
    if (response?.status === 200) {
      setPositions(response?.data?.data);
    }
  };
  const getManagerDatas = async () => {
    if (userPosition.value) {
      const newPositions = [...positions];
      let newArray = newPositions.filter((el) => el.id === userPosition.value - 1);
      if (userPosition.value === 5) {
        newArray = newPositions.filter((el) => el.id === 1);
      }
      const response = await AxiosAll(
        'get',
        `users/team?position=${newArray[0]?.name}`,
        {},
        user?.token
      );
      if (response?.status === 200) {
        setManagers(response?.data?.data);
      }
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleOnClose}>
      <DialogTitle
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
      >
        <div>Create User</div>
      </DialogTitle>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={3}>
            {/* First Column */}
            <Grid item xs={12} md={6}>
              <Stack spacing={3}>
                <div>
                  <LabelStyle>First Name</LabelStyle>
                  <RHFTextField name="fName" />
                </div>

                <div>
                  <LabelStyle>Email Address</LabelStyle>
                  <RHFTextField name="email" type="email" />
                </div>

                <div>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <LabelStyle>Select Position</LabelStyle>
                      {/* <InputLabel id="demo-simple-select-label">Select Position</InputLabel> */}
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="position"
                        value={userPosition.value}
                        // label="Position"
                        onChange={handleChange}
                        style={{ textTransform: 'capitalize' }}
                      >
                        {positions &&
                          positions.length > 0 &&
                          positions.map((item, index) => (
                            <MenuItem value={item.id} style={{ textTransform: 'capitalize' }}>
                              {item.label}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              </Stack>
            </Grid>

            {/* Second Column */}
            <Grid item xs={12} md={6}>
              <Stack spacing={3}>
                <div>
                  <LabelStyle>Last Name</LabelStyle>
                  <RHFTextField name="lName" />
                </div>

                <div>
                  <LabelStyle>Phone Number</LabelStyle>
                  <RHFTextField name="phone" type="number" />
                </div>

                <div>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      {/* <InputLabel id="demo-simple-select-label">Manger</InputLabel> */}
                      <LabelStyle>Manager</LabelStyle>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="manager"
                        value={managerPosition.value}
                        disabled={!userPosition.value || userPosition.value === 1}
                        // label="Manager"
                        onChange={ManageChange}
                        style={{ textTransform: 'capitalize' }}
                      >
                        {managers &&
                          managers?.length > 0 &&
                          managers?.map((item, index) => (
                            <MenuItem value={item.id} style={{ textTransform: 'capitalize' }}>
                              {item?.firstName} {item?.lastName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ backgroundColor: '#0351AB' }}
            loading={loading}
          >
            Submit
          </LoadingButton>
          <Button color="inherit" variant="outlined" onClick={handleOnClose}>
            Cancel
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
