import PropTypes from 'prop-types';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
// _mock
import { useMemo, useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';
import { request } from 'src/service/request';
import FormProvider from '../hook-form/form-provider';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

AddCustomerModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  getCustomers: PropTypes.func,
};

export default function AddCustomerModal({ open, setOpen, getCustomers }) {
  const outstandingSchema = Yup.object().shape({
    tsvFile: Yup.mixed().required('File is required'),
  });

  const user = useSelector((state) => state.user);
  const { token } = user;
  const [loading, setLoading] = useState(false);

  const defaultValues = useMemo(
    () => ({
      tsvFile: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(outstandingSchema),
    defaultValues,
  });
  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const bulkUpload = async (value) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('tsvFile', value.tsvFile[0]);
      const res = await request({
        method: 'POST',
        url: 'customers/bulk-add-customer-tsv',
        data: formData,
        token,
        contentType: 'multipart/form-data',
      });
      if (res.statusCode === 200) {
        getCustomers();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setOpen(false);
      reset(defaultValues);
    }
  };

  const onSubmit = async (value) => {
    try {
      bulkUpload(value);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={() => {
          setOpen(false);
          reset(defaultValues);
        }}
      >
        <DialogTitle>Customer Bulk Upload</DialogTitle>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Stack spacing={3}>
              <div>
                <LabelStyle>Upload Customers (in tsv File)</LabelStyle>
                <input
                  style={{
                    width: '100%',
                    padding: '10px',
                    border: '1px solid #d9d9d9',
                    borderRadius: '5px',
                  }}
                  name="tsvFile"
                  type="file"
                  accept=".tsv"
                  {...methods.register('tsvFile')}
                />
              </div>
            </Stack>
          </DialogContent>

          <DialogActions>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ backgroundColor: '#0351AB' }}
              loading={loading}
              disabled={isSubmitting}
            >
              Submit
            </LoadingButton>
            <Button
              color="inherit"
              variant="outlined"
              onClick={() => {
                setOpen(false);
                reset(defaultValues);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </div>
  );
}
