import packageEnv from '../package.json';

const ProjectType = (Env) => {
  switch (Env) {
    case 1:
      return {
        name: 'WHOLELEAF',
        shortName: 'WholeLeaf',
        largeLogo: '/assets/images/logo1.jpg',
        smallLogo: '/assets/images/logo1.jpg',
        stagingUrl: 'https://api.dev.node.wholeleaf.skunktest.work/api/',
        // stagingUrl: 'http://localhost:3004/api/',
        prodUrl: 'https://api.prod.node.wholeleaf.skunktest.work/api/',
      };
    case 2:
      return {
        name: 'VANGUARD',
        shortName: 'Vanguard',
        largeLogo: '/assets/images/Logo.webp',
        smallLogo: '/assets/images/vanguard.webp',
        stagingUrl: 'https://api.develpment.node.vanguard.skunktest.work/api/',
        // stagingUrl: 'http://localhost:3004/api/',
        prodUrl: 'https://api.dev.node.vanguard.skunktest.work/api/',
      };
    default:
      return {
        name: 'WHOLELEAF',
        shortName: 'WholeLeaf',
        largeLogo: '/assets/images/logo1.jpg',
        smallLogo: '/assets/images/logo1.jpg',
        stagingUrl: 'https://api.dev.node.wholeleaf.skunktest.work/api/',
        prodUrl: 'https://api.prod.node.wholeleaf.skunktest.work/api/',
      };
  }
};

export default {
  project: ProjectType(packageEnv?.project),
};
